<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Customer
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="First Name *"
            v-model="fields.first_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('first_name')"
            :error-messages="errors['first_name']"
          ></v-text-field>
          <v-text-field
            label="Last Name *"
            v-model="fields.last_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('last_name')"
            :error-messages="errors['last_name']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <!--  <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
          <v-switch
            label="Is Actively Looking"
            v-model="fields.is_actively_looking"
            inset
            :error="errors.hasOwnProperty('is_actively_looking')"
            :error-messages="errors['is_actively_looking']"
          ></v-switch>
          <v-select
            label="Renter Position"
            v-model="fields.renter_position"
            :items="positions"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('renter_position')"
            :error-messages="errors['renter_position']"
          ></v-select>-->
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      fields: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        is_actively_looking: false,
        renter_position: null,
      },
      positions: ["READY", "IN RENTED"],
      errors: {},
    };
  },

  methods: {
    open(customer = null) {
      if (customer !== null) {
        this.customer = customer;
        this.isEditing = true;
        this.fields.first_name = customer.first_name;
        this.fields.last_name = customer.last_name;
        this.fields.email = customer.email;
        this.fields.phone = customer.phone;
        this.fields.is_actively_looking = customer.is_actively_looking;
        this.fields.renter_position = customer.renter_position;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.customerId = this.customer.id;
      }

      this.$store
        .dispatch("easylets/customersStore/saveCustomer", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.customer = {};
      this.fields = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        is_actively_looking: false,
        renter_position: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
