<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-easylets-customers' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Customers</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="pr-12">
          <h1>{{ customer.full_name }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters> </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Basic Details</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.customerDialog.open(customer)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Customer's Information</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Full Name</th>
                      <td>{{ customer.full_name }}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ customer.email }}</td>
                    </tr>
                    <!--  <tr>
                          <th>Phone</th>
                          <td>{{ customer.phone }}</td>
                        </tr>
                        <tr>
                          <th>Renter Position</th>
                          <td>
                            {{ customer.renter_position }}
                          </td>
                        </tr>-->
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3"
              >Application Forms</v-card-title
            >
            <v-divider></v-divider>

            <v-data-table
              :headers="enquiriesTableHeaders"
              :items="customer.applications"
              no-data-text="No Applications found"
            >
              <template v-slot:item.property="{ item }">
                <span v-if="item.property_id">
                  {{ item.property.full_address }}
                </span>
                <span v-else>{{ item.address_text }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      v-on="on"
                      class="mr-2"
                      :to="{
                        name: 'module-easylets-applicationforms-individual',
                        params: { formId: item.application_id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item> </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <customer-dialog ref="customerDialog" />
  </div>
</template>

<script>
import CustomerDialog from "./components/CustomerDialog.vue";

export default {
  components: {
    CustomerDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Customers",
          disabled: false,
          to: { name: "module-easylets-customers" },
          exact: true,
        },
      ],

      enquiriesTableHeaders: [
        { text: "Property", value: "property" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.getters["easylets/customersStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.customer.full_name,
      disabled: true,
    });
  },
};
</script>
